import { AxiosRequestConfig } from "axios";
import { createApi } from "@reduxjs/toolkit/query/react";
import HttpsService from "../../../services/httpsService";
import { omit } from "lodash";

// Set up request function
const request = HttpsService.getHttps().request;

// Set up base query for rtk-query
const axiosBaseQuery = async (config: AxiosRequestConfig) => {
  try {
    const result = await request({
      headers: {
        "Content-Type": "application/json; charset=UTF-8"
      },
      ...config
    });
    return {
      data: result.data,
      meta: omit(result, ["data"])
    };
  } catch (axiosError) {
    const err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data
      }
    };
  }
};

export const mvcrApi = createApi({
  reducerPath: "mvcrApi",
  baseQuery: axiosBaseQuery,
  // Tags should always be singular.
  // Plurality is determined on a per-endpoint basis.
  // Each "type" specified in an api slice must be listed here
  tagTypes: [
    "Workspace",
    "User Settings",
    "Workspace Areas",
    "Workspace Share",
    "Filters",
    "Propagation Services",
    "Workspace Permissions",
    "Workspace Hierarchy",
    "WMS Weather URLs",
    "Notifications",
    "Users",
    "Areas",
    "Upload",
    "Feedbacks",
    "Goat Metadata",
    "Goat Report",
    "Goat Count",
    "Goat KMZ"
  ],
  // We'll inject all of our endpoints for organization + performance reasons
  endpoints: (build) => ({})
});

// Export API
export default mvcrApi;
