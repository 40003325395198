import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUseSliceSelector } from "../util";

export interface GoatState {
  overlayTimestamp: string;
  frequentUpdatesActivated: boolean;
}

const initialState: GoatState = {
  overlayTimestamp: "Currently inactive",
  frequentUpdatesActivated: false
};

export const goatStateSlice = createSlice({
  name: "goat-state",
  initialState,
  reducers: {
    /**
     * Track timestamp of last GoatOverlay pull
     */
    setOverlayTimestamp(state, action: PayloadAction<string>) {
      state.overlayTimestamp = action?.payload;
    },
    /**
     * Track activation state of frequent updates
     */
    setFrequentUpdatesActivated(state, action: PayloadAction<boolean>) {
      state.frequentUpdatesActivated = action?.payload;
    }
  }
});

export const { setOverlayTimestamp, setFrequentUpdatesActivated } =
  goatStateSlice.actions;

export const useGoatStateSelector = createUseSliceSelector(goatStateSlice);

export default goatStateSlice;
